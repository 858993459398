import {
  Anonymous,
  ApiTypesV1GroupMemberResponse,
  ApiTypesV1GroupResponse,
  ApiTypesV1QueryAllMembersRequest,
  ApiTypesV1QueryGroupMembersRequest,
  ApiTypesV1QueryNonGroupMembersRequest,
  ApiTypesV1SiteMemberProfileResponse,
  AppToastTypes,
  BadgesApi,
  BadgesMap,
  canFetchAllMembers,
  canSeeMembers,
  canSeeNonMembers,
  IMembershipAnswers,
  ItemsFilter,
  MembersApiOld,
  MembersBadgeIdsMap,
  MembershipAnswers,
  MembershipAnswersApi,
  MembersPublicApi,
  MembersQueryInitialResponse,
  MembersRequestBuilder,
  RequestState,
} from '@wix/social-groups-api';
import { PubSubEventTypes } from '../pubSub/PubSubEventTypes';
import { keyBy } from 'lodash';
import { Controller } from '../Controller';
import { PubSubObserver } from '../pubSub/PubSubObserver';
import {
  IMembersRequestState,
  MembersControllerProps,
} from './MembersControllerProps';

import { SearchFilterSortMembers } from './SearchFilterSortMembers';
import { MembersActionsType } from './MembersActions';

import { ConsoleLogger } from '../../../../common/loggers';
import { isEmptyObject } from '../../utils/utils';
import { AppToastsController } from '../../../../common/controllers/app-toasts/AppToastsController';
import { ControllerParams } from '@wix/yoshi-flow-editor';
import Url from 'url-parse';

export const MEMBERS_APP_DEF_ID = '14cc59bc-f0b7-15b8-e1c7-89ce41d0e0c9';

const SELECT_ALL_MEMBER_ID = 'select-all';
const EVERYONE_MENTION_GUID = 'everyone';
const EVERYONE_MENTION_DISPLAY_NAME = 'Everyone';

export class MembersController
  extends Controller<MembersControllerProps>
  implements PubSubObserver, MembershipAnswersApi
{
  private api!: MembersApiOld;
  private badgesApi!: BadgesApi;

  private readonly subscriptions: Map<number, PubSubEventTypes> = new Map();
  private readonly subscribers: Map<PubSubEventTypes, Function[]> = new Map();
  // for showing progress for members update we using membersToUpdate set, where we store ids of updating members
  // in case we add members by Select All option we add to the Set special id = SELECT_ALL_MEMBER_ID.
  private readonly membersToUpdate: Set<string> = new Set<string>();
  private readonly pendingRequests: Map<keyof MembersPublicApi, RequestState> =
    new Map();

  private followingMembers: string[] = [];
  private nonGroupMembersCount!: number;
  private readonly siteMembersMap: {
    [id: string]: ApiTypesV1SiteMemberProfileResponse;
  } = {};

  private badges: BadgesMap = {};
  private membersBadgeIds: MembersBadgeIdsMap = {};

  private groupMembers: Map<string, ApiTypesV1GroupMemberResponse> = new Map();
  private groupMembersNextCursor: string | null | undefined = null;

  private readonly setMembers = async (
    group: ApiTypesV1GroupResponse,
    memberIdsToUpdate: string[] = [],
  ) => {
    try {
      const { members } = await this.getMembers(group, memberIdsToUpdate);
      this.setMembersProps(members);
    } catch (e) {
      ConsoleLogger.log(e);
      this.errorLogger.log(e);
    }
  };

  private async setMembersProps(
    membersToUpdate: ApiTypesV1GroupMemberResponse[] = [],
    memberIdsToDelete: string[] = [],
  ) {
    membersToUpdate.forEach((memberToUpdate: ApiTypesV1GroupMemberResponse) => {
      this.groupMembers.set(memberToUpdate.siteMemberId!, memberToUpdate);
    });

    this.setMembersBadges(
      membersToUpdate.map(
        (m: ApiTypesV1GroupMemberResponse) => m.siteMemberId!,
      ),
    );

    memberIdsToDelete &&
      memberIdsToDelete.forEach((memberIdToDelete) => {
        this.groupMembers.delete(memberIdToDelete);
      });

    this.setMembersMap(membersToUpdate);

    this.setProps({
      members: Array.from(this.groupMembers.values()),
      siteMembersMap: this.siteMembersMap,
      membersUpdate: Array.from(this.membersToUpdate),
      badges: this.badges,
      membersBadgeIds: this.membersBadgeIds,
      hasMoreGroupMembers: this.hasMoreGroupMembers(),
    });
  }

  /**
   *  Scenarios:
   *  1. Logged in
   *    1.A. Profile Public
   *      1.A.1. - public group
   *        1.A.1.a - member
   *        1.A.1.b - not a member
   *      1.A.2. - private group
   *        1.A.2.a - member
   *        1.A.2.b - not a member
   *    1.B. Profile Private
   *  2. Not logged
   *
   * @param group
   * @param memberIds to get
   */
  private async getMembers(
    group: ApiTypesV1GroupResponse,
    memberIds?: string[],
  ) {
    if (!canSeeMembers(group)) {
      return {};
    }
    const promises: Promise<any>[] = [
      memberIds && memberIds.length
        ? this.filterGroupMembersWithIds(group.groupId!, memberIds)
        : this.fetchGroupMembers(group.groupId!),
    ];
    if (this.isUserLoggedIn()) {
      promises.push(this.getCurrentSiteMember());
    }
    const [members] = await Promise.all(promises);
    this.setMembersMap(members);

    return {
      members,
      siteMembersMap: this.siteMembersMap,
    };
  }

  private async getBadges() {
    return this.badgesApi.getBadges();
  }

  private async setMembersBadges(memberIds: string[]) {
    if (!memberIds.length) {
      return;
    }

    const membersBadgeIds = await this.badgesApi.getMembersBadgeIds(memberIds);
    Object.assign(this.membersBadgeIds, membersBadgeIds);
    this.setProps({
      membersBadgeIds: this.membersBadgeIds,
    });
  }

  private setMembersMap(siteMembers: ApiTypesV1SiteMemberProfileResponse[]) {
    // TODO: ? refactor to array reduce || Map
    const siteMembersMap = keyBy(siteMembers, 'siteMemberId');
    Object.assign(this.siteMembersMap, siteMembersMap);
  }

  private async getNonGroupMembersCount(groupId: string): Promise<number> {
    try {
      const total = await this.getSiteMembersCount();
      const { memberCount, pendingMemberCount } = this.group;
      return Math.abs(total! - memberCount! - (pendingMemberCount || 0));
    } catch (e) {
      ConsoleLogger.log('Get non group members count: FAIL', e);
      this.errorLogger.log(e);
    }
    return 0;
  }

  private async getSiteMembersCount() {
    try {
      const { metadata } = await this.api.getSiteMembersCount();
      return metadata!.total || 0;
    } catch (e) {
      ConsoleLogger.log('Get site members count: FAIL', e);
      this.errorLogger.log(e);
    }
    return 0;
  }

  /**
   * Get current site member (basically to know if the member has a private profile)
   */
  private async getCurrentSiteMember() {
    if (!this.isUserLoggedIn()) {
      return;
    }
    // TODO: get from cache
    try {
      const { member } = await this.api.getCurrentSiteMember();
      return member;
    } catch (e) {
      console.error('Get current site member profile: FAIL', e);
      this.errorLogger.log(e);
    }
  }

  private async queryAllMembers(
    query: ApiTypesV1QueryAllMembersRequest,
  ): Promise<ApiTypesV1GroupMemberResponse[]> {
    try {
      const {
        data: { members },
      } = await this.api.queryAllMembers(query);
      return members!;
    } catch (e) {
      console.log('[MembersController.queryAllMembers] FAIL');
      this.errorLogger.log(e);
    }
    return [];
  }

  private async queryGroupMembers(
    query: ApiTypesV1QueryGroupMembersRequest,
  ): Promise<ApiTypesV1GroupMemberResponse[]> {
    try {
      const {
        data: { members },
      } = await this.api.queryGroupMembers(query);
      return members!;
    } catch (e) {
      ConsoleLogger.log('FAIL to fetch non group members', e);
      this.errorLogger.log(e);
    }
    return [];
  }

  private async queryNonGroupMembers(
    queryReq: ApiTypesV1QueryNonGroupMembersRequest = { query: {} },
  ): Promise<ApiTypesV1SiteMemberProfileResponse[] | undefined> {
    if (!canSeeNonMembers(this.group)) {
      return;
    }
    // TODO: get BE guys to implement paging for this request and remove this code
    if (queryReq.query && queryReq.query.paging) {
      return this.getLimitedMembers(queryReq);
    }
    // TODO: use cache?
    try {
      const {
        data: { members },
      } = await this.api.queryNonGroupMembers(queryReq);
      return members!;
    } catch (e) {
      ConsoleLogger.log('FAIL to fetch non group members', e);
      this.errorLogger.log(e);
    }
    return [];
  }

  // TODO: get BE guys to implement paging for this request and remove this code
  private async getLimitedMembers(
    queryReq: ApiTypesV1QueryNonGroupMembersRequest,
  ) {
    try {
      const { limit, offset } = queryReq.query?.paging as any;
      delete queryReq.query?.paging;
      const members = await this.queryNonGroupMembers(queryReq);
      const start = offset || 0;
      const end = Math.min(limit || members!.length, members!.length);
      return members!.slice(start, end);
    } catch (e) {
      ConsoleLogger.log('FAIL to fetch non group members', e);
      this.errorLogger.log(e);
    }
  }

  private fetchMoreGroupMembers = async (
    groupId: string,
  ): Promise<ApiTypesV1GroupMemberResponse[]> => {
    try {
      const { data } = await this.api.listGroupMembers(groupId, {
        includeDeleted: true,
        limit: 10,
        cursorToken: this.groupMembersNextCursor ?? undefined,
      });

      this.groupMembersNextCursor = data.cursorTokens?.next;

      // BE returns members with gaps (some members are not deleted properly and stay exists in db)
      if (
        data.members &&
        data.members.length === 0 &&
        this.groupMembersNextCursor
      ) {
        return this.fetchMoreGroupMembers(groupId);
      }
      this.setMembersProps(data.members!);
      return Array.from(this.groupMembers.values());
    } catch (e) {
      ConsoleLogger.log(e);
      this.errorLogger.log(e);
    }
    return [];
  };

  private async fetchGroupMembers(
    groupId: string,
  ): Promise<ApiTypesV1GroupMemberResponse[]> {
    this.groupMembers.clear();
    this.groupMembersNextCursor = null;
    return this.fetchMoreGroupMembers(groupId);
  }

  private hasMoreGroupMembers(): boolean {
    return !!this.groupMembersNextCursor;
  }

  private readonly filterMembersByName = async (
    group: ApiTypesV1GroupResponse,
    searchQuery: string,
    limit?: number,
  ) => {
    let filteredMembers: any = [];

    const queryRequest = new MembersRequestBuilder().filterNameContains(
      searchQuery,
    );
    if (limit) {
      queryRequest.addPaging(limit);
    }
    const query = queryRequest.build();

    query.groupId = group.groupId;
    try {
      if (canFetchAllMembers(group)) {
        filteredMembers = await this.queryAllMembers(query);
      } else {
        filteredMembers = await this.queryGroupMembers(query);
      }

      if (
        this.experimentEnabled('specs.groups.EveryoneMention') &&
        (searchQuery === '' ||
          EVERYONE_MENTION_DISPLAY_NAME.toLowerCase().includes(searchQuery))
      ) {
        filteredMembers.unshift({
          siteMemberId: EVERYONE_MENTION_GUID,
          name: {
            nick: EVERYONE_MENTION_DISPLAY_NAME,
          },
        });
      }
    } catch (e) {
      ConsoleLogger.log(e);
      this.errorLogger.log(e);
    } finally {
      this.setProps({
        membersQueryResponse: {
          query: searchQuery,
          members: filteredMembers,
        },
      });
    }
  };

  private readonly searchFilterSort = async (q: SearchFilterSortMembers) => {
    // TODO: query request status
    try {
      const groupId = await this.getGroupId();
      const members = await this.getFilteredMembers(groupId!, q);
      return this.setProps({ members });
    } catch (e) {
      console.log('[MembersController.searchFilterSort] Error');
      this.errorLogger.log(e);
    }
  };

  private getFilteredMembers(groupId: string, q: SearchFilterSortMembers) {
    const { name, roles, sort } = q;
    if (isEmptyObject(q)) {
      // list group members
      return this.fetchGroupMembers(groupId);
    }
    const builder = new MembersRequestBuilder();
    builder
      .filterNameContains(name!)
      .filterWithRoles(roles!)
      .withGroupId(groupId);

    if (sort) {
      builder.addSort(sort.field, sort.order);
    }

    return this.queryGroupMembers(builder.build());
  }

  private readonly addAllNonGroupMembers = async (
    group: ApiTypesV1GroupResponse,
    excludedSiteMemberIds: string[] = [],
  ) => {
    try {
      const groupId = group.groupId!;
      await this.sendAddAllRequest(groupId, excludedSiteMemberIds);
      await this.fetchMoreGroupMembers(groupId);
      await this.sendMembersRequests(groupId);
      //    TODO: fix Toasts  this.showMembersAddedToast(count);
      await this.waitForGroupUpdated(groupId);
      return this.finishAddingMembers();
    } catch (e) {
      ConsoleLogger.log(e);
      this.errorLogger.log(e);
    }
  };

  private async sendAddAllRequest(
    groupId: string,
    excludedSiteMemberIds: string[],
  ) {
    const all = 'addAllNonGroupMembersToGroup';
    this.setAddingMembersInProgress([SELECT_ALL_MEMBER_ID], all);
    try {
      await this.api.addAllNonGroupMembersToGroup(
        groupId,
        excludedSiteMemberIds,
      );
      this.pendingRequests.set(all, RequestState.SUCCESS);
    } catch (e) {
      this.errorLogger.log(e);
      this.pendingRequests.set(all, RequestState.ERROR);
      this.membersToUpdate.clear();
      this.setProps({
        membersRequest: this.pendingRequestsToRequestState(),
      });
      throw e;
    }
  }

  private readonly addMembers = async (
    group: ApiTypesV1GroupResponse,
    memberIds: string[],
  ) => {
    try {
      await this.sendAddMembersRequests(group, memberIds);
      this.showMembersAddedToast(memberIds.length);
      await this.waitForGroupUpdated(group.groupId!);
      return this.finishAddingMembers();
    } catch (e) {
      this.errorLogger.log(e);
    }
  };

  private async sendAddMembersRequests(
    group: ApiTypesV1GroupResponse,
    memberIds: string[],
  ) {
    const groupId = group.groupId!;
    const add = 'addToGroup';
    this.setAddingMembersInProgress(memberIds, add);
    try {
      await this.api.addToGroup(groupId, memberIds);
      this.pendingRequests.set(add, RequestState.SUCCESS);
    } catch (e) {
      this.errorLogger.log(e);
      this.pendingRequests.set(add, RequestState.ERROR);
      this.membersToUpdate.clear();
      return this.setProps({
        membersRequest: this.pendingRequestsToRequestState(),
      });
    }
    this.setMembers(group, memberIds);
    return this.sendMembersRequests(groupId);
  }

  private async sendMembersRequests(groupId: string) {
    try {
      this.addPendingRequest('queryNonGroupMembers');
      this.setProps({ membersRequest: this.pendingRequestsToRequestState() });

      const siteMembers = await this.queryNonGroupMembers({ groupId });
      this.pendingRequests.set('queryNonGroupMembers', RequestState.SUCCESS);
      this.setMembersMap(siteMembers!);
      this.setProps({
        siteMembers,
        siteMembersMap: this.siteMembersMap,
        membersRequest: this.pendingRequestsToRequestState(),
      });
    } catch (e) {
      this.errorLogger.log(e);
      this.pendingRequests.set('queryNonGroupMembers', RequestState.ERROR);
      this.setProps({
        membersRequest: this.pendingRequestsToRequestState(),
      });
      throw e;
    }
  }

  private setAddingMembersInProgress(
    memberIds: string[],
    add: keyof MembersPublicApi,
  ) {
    memberIds.forEach((memberId) => {
      this.membersToUpdate.add(memberId);
    });
    this.controllerConfig.setProps({
      membersUpdate: Array.from(this.membersToUpdate),
      membersRequest: this.addPendingRequest(add),
    } as Partial<MembersControllerProps>);
  }

  private waitForGroupUpdated(groupId: string) {
    if (!this.subscribers.get(PubSubEventTypes.DID_UPDATE_GROUP_EVENT)) {
      this.subscribers.set(PubSubEventTypes.DID_UPDATE_GROUP_EVENT, []);
    }
    const subscribers = this.subscribers.get(
      PubSubEventTypes.DID_UPDATE_GROUP_EVENT,
    );
    const subscriber = new Promise(
      (resolve: (value: any) => void, p2: (reason?: any) => void) => {
        subscribers!.push(resolve);
      },
    );
    this.publishGroupUpdate(groupId);
    return subscriber;
  }

  private publishGroupUpdate(groupId: string) {
    try {
      this.controllerConfig.platformAPIs.pubSub.publish(
        PubSubEventTypes.UPDATE_GROUP_EVENT,
        groupId,
        false,
      );
    } catch (e) {
      ConsoleLogger.log(e);
      this.errorLogger.log(e);
    }
  }

  private publishGroupWillUpdate(groupId: string) {
    try {
      this.controllerConfig.platformAPIs.pubSub.publish(
        PubSubEventTypes.WILL_UPDATE_GROUP_EVENT,
        groupId,
        false,
      );
    } catch (e) {
      ConsoleLogger.log(e);
      this.errorLogger.log(e);
    }
  }

  // TODO: controllers and toasts? UI and business logic mess?
  private showMembersAddedToast(membersAddedCount: number) {
    try {
      AppToastsController.publish(this.controllerConfig.platformAPIs.pubSub, {
        type: AppToastTypes.MEMBERS_ADDED,
        options: {
          count: membersAddedCount,
        },
      });
    } catch (e) {
      ConsoleLogger.log(e);
      this.errorLogger.log(e);
    }
  }

  private readonly openCurrentUserProfile = () => {
    this.getLocation().to?.('/account/profile');
  };

  constructor(
    controllerContext: ControllerParams,
    private group: ApiTypesV1GroupResponse,
  ) {
    super(controllerContext, group.groupId!);
    this.setApi(this.getSiteToken()!);
    this.setSubscriptions();
    this.onUserLogin(async () => {
      this.setApi(this.getSiteToken()!);
      try {
        await this.setMembers(group);
      } catch (e) {
        ConsoleLogger.log(e);
        this.errorLogger.log(e);
      }
    });
  }

  onBeforeUnLoad() {
    this.removeSubscriptions();
  }

  removeSubscriptions() {
    // https://wix.slack.com/archives/CAKBA7TDH/p1601480311035300
    // TODO: remove after fixed on TB side

    try {
      const storage = this.controllerConfig.platformAPIs.storage.session;
      const key = 'MembersController.setSubscriptions';
      const subscr = JSON.parse(storage.getItem(key)!);
      if (!subscr) {
        return;
      }
      for (const [id, event] of Object.entries(subscr) as any) {
        this.controllerConfig.platformAPIs.pubSub.unsubscribe(event, id);
      }
      storage.removeItem(key);
    } catch (e) {
      console.log('Error in MembersController.removeSubscriptions');
    }
    //
    try {
      this.subscriptions.forEach((event, subscrId) => {
        this.controllerConfig.platformAPIs.pubSub.unsubscribe(event, subscrId);
      });
    } catch (e) {
      ConsoleLogger.log(e);
    } finally {
      this.subscriptions.clear();
    }
  }

  private setApi(siteToken: string) {
    this.api = new MembersApiOld(siteToken, this.getApiBaseUrl());
    const badgesApiBaseUrl = new Url(this.getLocation().baseUrl).origin;
    this.badgesApi = new BadgesApi(siteToken, badgesApiBaseUrl);
  }

  setSubscriptions() {
    try {
      this.controllerConfig.platformAPIs.pubSub.subscribe(
        PubSubEventTypes.DID_UPDATE_GROUP_EVENT,
        this.onDidUpdateGroup,
        false,
      );
      this.controllerConfig.platformAPIs.pubSub.subscribe(
        PubSubEventTypes.FEED_AUTHORS_IDS,
        this.onFeedAuthorsIds,
        false,
      );
    } catch (e) {
      ConsoleLogger.log(e);
      this.errorLogger.log(e);
    }
  }

  private readonly onDidUpdateGroup = async ({ data }: any) => {
    try {
      this.group = JSON.parse(data);
      if (this.membersToUpdate.size) {
        this.membersToUpdate.clear();
      }
      // notify group update publishers
      const subscribers = this.subscribers.get(
        PubSubEventTypes.DID_UPDATE_GROUP_EVENT,
      );

      if (subscribers) {
        subscribers.forEach((resolve) => resolve(this.group));
        this.subscribers.delete(PubSubEventTypes.DID_UPDATE_GROUP_EVENT);
      } else {
        // relationship with group or permittedToInvite can be changed - so need to fetch members one more time
        // (due to those params we decide can we fetch all site members or only group members)
        return this.setMembers(this.group);
      }
    } catch (e) {
      console.log('Failed in DID_UPDATE_GROUP_EVENT');
      this.errorLogger.log(e);
    }
  };

  private addingMembersInProgress() {
    return (
      this.pendingRequests.get('addToGroup') ||
      this.pendingRequests.get('addAllNonGroupMembersToGroup')
    );
  }

  private removeAddingMembersFromPending() {
    this.pendingRequests.delete('addToGroup');
    this.pendingRequests.delete('addAllNonGroupMembersToGroup');
    this.pendingRequests.delete('queryNonGroupMembers');
  }

  setProps(props: Partial<MembersControllerProps>) {
    this.controllerConfig.setProps(props);
  }

  async pageReady(): Promise<void> {
    this.setProps({
      membersActions: this.getMembersActions(),
    });
    return;
  }

  private getMembersActions(): MembersActionsType {
    return {
      addMembers: this.addMembers,
      addAllNonGroupMembers: this.addAllNonGroupMembers,
      changeMemberRoleInGroup: this.changeMemberRoleInGroup,
      filterMembers: this.filterMembersByName,
      getNewMembers: this.getNewMembers,
      openCurrentUserProfile: this.openCurrentUserProfile,
      removeMembersFromGroup: this.removeMembersFromGroup,
      setMembers: this.setMembers,
      setNonGroupMembers: this.setNonGroupMembers,
      listMembershipQuestionAnswers: this.listMembershipQuestionAnswers,
      searchFilterSort: this.searchFilterSort,
      fetchMoreGroupMembers: this.fetchMoreGroupMembers,
    };
  }

  async getInitialProps(): Promise<Partial<MembersControllerProps>> {
    // TODO: initial props for members tab

    const [{ siteMembersMap, members }, badges] = await Promise.all([
      this.getMembers(this.group),
      this.getBadges(),
    ]);

    this.badges = badges;

    const initialProps: Partial<MembersControllerProps> = {
      membersQueryResponse: MembersQueryInitialResponse,
      siteMembersMap,
      members,
      badges: this.badges,
      membersBadgeIds: this.membersBadgeIds,
    };
    return Promise.resolve(initialProps);
  }

  removeMembersFromGroup = async (groupId: string, siteMemberIds: string[]) => {
    try {
      await this.api.removeFromGroup(groupId, siteMemberIds);
      if (this.groupMembersNextCursor) {
        this.groupMembersNextCursor = `${
          parseInt(this.groupMembersNextCursor, 10) - 1
        }`;
      }
      this.setMembersProps([], siteMemberIds);
    } catch (e) {
      ConsoleLogger.log(e);
      this.errorLogger.log(e);
    } finally {
      this.waitForGroupUpdated(groupId);
    }
  };

  private async filterGroupMembersWithIds(
    groupId: string,
    memberIds: string[],
  ): Promise<ApiTypesV1GroupMemberResponse[]> {
    const builder = new MembersRequestBuilder();
    builder.filterWithIds(memberIds).withGroupId(groupId);
    return this.queryGroupMembers(builder.build());
  }

  changeMemberRoleInGroup = async (
    groupId: string,
    siteMemberId: string,
    role: any,
  ) => {
    try {
      // TODO: optimistic update?
      await this.api.setMemberRole(groupId, siteMemberId, role);
      await this.setMembers(this.group, [siteMemberId]);
    } catch (e) {
      ConsoleLogger.log(e);
      this.errorLogger.log(e);
    } finally {
      this.waitForGroupUpdated(groupId);
    }
  };

  getNewMembers = async (group: ApiTypesV1GroupResponse, startDate: Date) => {
    if (!canSeeMembers(group)) {
      return;
    }
    let newMembers;
    try {
      // "query":{ "filter":{"dateUpdated":{"$gte":"2019-10-25T08:40:20.120Z[UTC]"}}}
      const { data } = await this.api.countNewMembers(group.groupId!, {
        filter: {
          dateUpdated: { $gte: startDate.toISOString() },
        },
      });
      newMembers = data.newMembers;
    } catch (e) {
      console.error(e);
      this.errorLogger.log(e);
      newMembers = 0;
    }
    this.setProps({ newMembers } as any);
  };

  private readonly onFeedAuthorsIds = async ({
    data: ids = [],
  }: {
    data: string[];
  }) => {
    // filter out ids that not in `this.siteMembersMap`
    const _ids = ids.filter((id) => !this.siteMembersMap[id]);

    if (!_ids.length) {
      return;
    }

    const q = new MembersRequestBuilder().filterWithIds(_ids).build();
    q.groupId = await this.getGroupId();
    // try to get profiles from non group members
    // and try to get profiles from group members if there are still authors that are not in `this.siteMembersMap` (i.e. members > default limit of member request)
    const [groupMembers, nonGroupMembers] = await Promise.all([
      this.queryGroupMembers(q),
      this.isUserLoggedIn()
        ? this.queryNonGroupMembers(q)
        : Promise.resolve([]),
    ]);

    const map = {
      ...keyBy(nonGroupMembers, 'siteMemberId'),
      ...keyBy(groupMembers, 'siteMemberId'),
    };

    this.setMembersMap(
      _ids.map(
        (siteMemberId) =>
          map[siteMemberId] || {
            ...Anonymous,
            siteMemberId,
          },
      ),
    );
    await this.setMembersBadges(Object.keys(this.siteMembersMap));
    this.setProps({ siteMembersMap: this.siteMembersMap });
  };

  private readonly setNonGroupMembers = async (
    queryReq: ApiTypesV1QueryGroupMembersRequest = { query: {} },
  ) => {
    if (!canSeeNonMembers(this.group)) {
      return;
    }
    const queryNonGroupMembers = 'queryNonGroupMembers';
    this.setProps({
      membersRequest: this.addPendingRequest(queryNonGroupMembers),
    });
    const props: Partial<MembersControllerProps> =
      await this.getNonGroupMembers(queryReq);
    this.removePendingRequest(queryNonGroupMembers);
    this.removeAddingMembersFromPending();
    props.membersRequest = this.pendingRequestsToRequestState();
    this.setProps(props);
  };

  private async getNonGroupMembers(
    queryReq: ApiTypesV1QueryGroupMembersRequest = { query: {} },
  ) {
    const groupId = await this.getGroupId();
    queryReq.groupId = groupId;
    const [siteMembers] = await Promise.all([
      this.queryNonGroupMembers(queryReq),
      this.setNonGroupMembersCount(groupId!),
    ]);
    this.setMembersMap(siteMembers!);
    await this.setMembersBadges(Object.keys(this.siteMembersMap));
    const props = {
      siteMembersMap: this.siteMembersMap,
      siteMembers,
      nonGroupMembersCount: this.nonGroupMembersCount,
      membersUpdate: Array.from(this.membersToUpdate),
    };
    return props;
  }

  private addPendingRequest(
    pending: keyof MembersPublicApi,
  ): IMembersRequestState {
    this.pendingRequests.set(pending, RequestState.PENDING);
    return this.pendingRequestsToRequestState();
  }

  private pendingRequestsToRequestState() {
    try {
      return Object.fromEntries(this.pendingRequests);
    } catch (e) {
      // Object.fromEntries doesn't work for IE
      // TODO: log sentry to know how many IE cases
      return {};
    }
  }

  private removePendingRequest(pending: keyof MembersPublicApi) {
    this.pendingRequests.delete(pending);
    return this.pendingRequestsToRequestState();
  }

  private async setNonGroupMembersCount(groupId: string) {
    this.nonGroupMembersCount = await this.getNonGroupMembersCount(groupId);
  }

  listMembershipQuestionAnswers = async (
    groupId: string,
    siteMemberIds: string[],
    itemsFilter: ItemsFilter,
  ): Promise<IMembershipAnswers> => {
    try {
      const { data } = await this.api.listMembershipQuestionAnswers(
        groupId,
        siteMemberIds,
        itemsFilter,
      );
      const questionsAnswers = MembershipAnswers.fromResponse(data);
      this.setProps({ questionsAnswers });
    } catch (e) {
      console.log('Error in MembersController.listMembershipQuestionAnswers');
      this.errorLogger.log(e);
    }
    return null as any;
  };

  private async finishAddingMembers() {
    await this.setNonGroupMembersCount(this.group.groupId!);
    this.removeAddingMembersFromPending();
    this.membersToUpdate.clear();
    this.setProps({
      nonGroupMembersCount: this.nonGroupMembersCount,
      membersRequest: this.pendingRequestsToRequestState(),
      membersUpdate: [],
    });
  }
}
